<script>
export default {
  name: 'Avatar',
  props: {
    username: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 40,
    },
  },
  computed: {
    style() {
      return {
        fontSize: `${Math.floor(this.size / 2.5)}px`,
      };
    },
    userInitial() {
      const parts = this.username.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },
  },
};
</script>

<template>
  <div
    class="avatar-container hover:avatar-container-hover"
    :style="style"
    aria-hidden="true"
  >
    <slot>{{ userInitial }}</slot>
  </div>
</template>

<style scoped>
@tailwind components;
@layer components {
  .avatar-color {
    /* background-image: linear-gradient(to top, #d8fdd2 0%, #d8fdd2 100%); */
    background-color: #d8fdd2;
  }

  .dark-avatar-color {
    background-color: #d8fdd2;
  }

  .avatar-hover {
    /* background-image: linear-gradient(to top, #00a88430 0%, #00a88430 100%); */
    background-color: #d8fdd2;
  }

  .dark-avatar-hover {
    background-color: #d8fdd2;
  }
}

.avatar-container {
  @apply flex leading-[100%] font-medium items-center justify-center text-center cursor-default avatar-color dark:dark-avatar-color text-[#00a884] dark:text-[#00a884];
  transition:
    background-image 0.3s,
    color 0.3s; /* Smooth transition for hover effect */
}

.avatar-container:hover {
  @apply avatar-hover dark:dark-avatar-hover;
}
</style>
