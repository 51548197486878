<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    isChildMenuActive: {
      type: Boolean,
      default: false,
    },
    openInNewPage: {
      type: Boolean,
      default: false,
    },
  },
  // data() {
  //   return {
  //     TestingIcon,
  //   };
  // },
  methods: {
    // Dynamically constructs the image path based on the 'name' prop
    getImageSrc(name) {
      try {
        // eslint-disable-next-line global-require
        return require(`../../../assets/images/${name}.png`);
      } catch (e) {
        // eslint-disable-next-line global-require
        return require(`../../../assets/images/chat.svg`); // Return a fallback image or empty string
      }
    },
  },
};
</script>

<template>
  <router-link v-slot="{ href, isActive, navigate }" :to="to" custom>
    <a
      v-tooltip.right="$t(`SIDEBAR.${name}`)"
      :href="href"
      class="text-[#FFFFFF] dark:text-slate-100 w-10 h-10 my-2 flex items-center justify-center rounded-lg hover:bg-[#d7f2ff5c] dark:hover:bg-slate-700 dark:hover:text-slate-100 hover:text-[#FFFFFF] relative"
      :class="{
        'bg-[#d7f2ff5c] dark:bg-slate-800 text-[#FFFFFF] hover:bg-[#d7f2ff5c]':
          isActive || isChildMenuActive,
      }"
      :rel="openInNewPage ? 'noopener noreferrer nofollow' : undefined"
      :target="openInNewPage ? '_blank' : undefined"
      @click="navigate"
    >
      <img :src="getImageSrc(icon)" :alt="TestingIcon" />
      <span class="sr-only">{{ name }}</span>
      <span
        v-if="count"
        class="text-black-900 bg-yellow-500 absolute -top-1 -right-1"
      >
        {{ count }}
      </span>
    </a>
  </router-link>
</template>
