<script>
export default {
  name: 'WootDropdownItem',
  componentName: 'WootDropdownMenu',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <li
    class="mb-1 list-none dropdown-menu__item text-[#000000] bg-black"
    :class="{
      'is-disabled': disabled,
    }"
    :tabindex="disabled ? null : -1"
    :aria-disabled="disabled"
  >
    <slot />
  </li>
</template>

<style lang="scss" scoped>
.dropdown-menu__item {
  @apply mb-1 list-none text-[#000000] bg-[#FFFFFF];
  padding: 4px 10.8px;

  // Ensure text color for buttons within the li
  ::v-deep {
    a,
    .button {
      @apply inline-flex whitespace-nowrap w-full text-left rtl:text-right;
      color: #000000 !important; // Explicitly set text color to black
      background: #00a88435 !important; // Ensure background is transparent
    }

    .button__content {
      color: #000000 !important; // Ensure text color for button content
    }
  }

  // Ensure li element styling is consistent
  background: #fff;
  color: #000000 !important;
}

// A hacky fix to remove the background that came from the foundation styles node module file
// Can be removed once we remove the foundation styles node module
.dropdown.menu {
  // Top-level item
  > li > a {
    background: transparent;
    padding: 4px 10.8px;
  }
}
</style>
